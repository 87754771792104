import validate from "/etc/nginx/sites-available/ulaznice.de/node_modules/nuxt/dist/pages/runtime/validate.js";
import scroll_45top_45global from "/etc/nginx/sites-available/ulaznice.de/middleware/scrollTop.global.ts";
import manifest_45route_45rule from "/etc/nginx/sites-available/ulaznice.de/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  scroll_45top_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "is-admin": () => import("/etc/nginx/sites-available/ulaznice.de/middleware/isAdmin.ts"),
  "is-client": () => import("/etc/nginx/sites-available/ulaznice.de/middleware/isClient.ts"),
  "is-payspot": () => import("/etc/nginx/sites-available/ulaznice.de/middleware/isPayspot.ts"),
  "is-scanner": () => import("/etc/nginx/sites-available/ulaznice.de/middleware/isScanner.ts"),
  "layout-global": () => import("/etc/nginx/sites-available/ulaznice.de/middleware/layoutGlobal.ts")
}