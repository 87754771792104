import { default as indexA7CZ3Xpga2Meta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/client/[id]/index.vue?macro=true";
import { default as payoutsn6dILLKAJ9Meta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/client/[id]/payouts.vue?macro=true";
import { default as statsnwmKzreI4wMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/client/[id]/stats.vue?macro=true";
import { default as addXgbV0oaE7WMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/client/add.vue?macro=true";
import { default as index2BuX5Mf1KYMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/client/index.vue?macro=true";
import { default as indexLXvG1VhT7KMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/deploy/index.vue?macro=true";
import { default as indexss8hUfI7iHMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/event/[id]/event-manager/index.vue?macro=true";
import { default as indexFY8v0928ItMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/event/[id]/index.vue?macro=true";
import { default as add7GstNqrXo3Meta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/event/add.vue?macro=true";
import { default as indexxEUEGQv3WaMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/event/index.vue?macro=true";
import { default as indexhnmDXrslDmMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/index.vue?macro=true";
import { default as indexAWrWSHgJBFMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/order/[id]/index.vue?macro=true";
import { default as index6lPnhw5Gm2Meta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/order/index.vue?macro=true";
import { default as indexyv1oickYpeMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/pages/[id]/index.vue?macro=true";
import { default as addwhoJTrsswKMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/pages/add.vue?macro=true";
import { default as indexSaJKz81WhJMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/pages/index.vue?macro=true";
import { default as indexkT9euVLpJbMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/payouts/index.vue?macro=true";
import { default as index4TdVT9c7wVMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/place/[id]/index.vue?macro=true";
import { default as addm0qeuL0r1vMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/place/add.vue?macro=true";
import { default as index03MLA3UToAMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/place/index.vue?macro=true";
import { default as indexXiFVxfFIEaMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/requests/index.vue?macro=true";
import { default as design0WzTzT3NkQMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/settings/design.vue?macro=true";
import { default as emailJGqFcjTDT5Meta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/settings/email.vue?macro=true";
import { default as indexNuwoOcA5RxMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/settings/index.vue?macro=true";
import { default as emailq2anKU35UVMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/settings/marketing/email.vue?macro=true";
import { default as indexCEOciNeq9aMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/settings/marketing/index.vue?macro=true";
import { default as smsQZuBfGJVvuMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/settings/marketing/sms.vue?macro=true";
import { default as whatsapp1uHAG3Kw8FMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/settings/marketing/whatsapp.vue?macro=true";
import { default as indexpk0TCUAxwGMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/sponsor/[id]/index.vue?macro=true";
import { default as addk2D5Y7pXalMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/sponsor/add.vue?macro=true";
import { default as index0rYY0SbiuRMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/sponsor/index.vue?macro=true";
import { default as indexBO1LMQ7jOLMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/user/[id]/index.vue?macro=true";
import { default as add7BlkmSQpGpMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/user/add.vue?macro=true";
import { default as indexYH9BNAL52pMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/admin/user/index.vue?macro=true";
import { default as archive4La1ZRVWujMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/archive.vue?macro=true";
import { default as cancel7YWRJ2xc7TMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/checkout/cancel.vue?macro=true";
import { default as indextecaepTb2XMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/checkout/index.vue?macro=true";
import { default as successSn2J8YaGKtMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/checkout/success.vue?macro=true";
import { default as indexBes5HwpDcwMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/client/[id]/event/index.vue?macro=true";
import { default as indexBgBcNhYDIaMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/client/[id]/index.vue?macro=true";
import { default as addWjFa6wly5gMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/client/[id]/payouts/add.vue?macro=true";
import { default as index4enIUcivCpMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/client/[id]/payouts/index.vue?macro=true";
import { default as settingsO4VYeDH4zlMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/client/[id]/settings.vue?macro=true";
import { default as collectGIjSbtO0YnMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/collect.vue?macro=true";
import { default as indexRaU92c6QqHMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/event/[slug]/index.vue?macro=true";
import { default as indexRB8wOtS1nFMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/event/genre/index.vue?macro=true";
import { default as indexcsM6pMpg7GMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/event/tag/index.vue?macro=true";
import { default as indexHd3pSx4eV0Meta } from "/etc/nginx/sites-available/ulaznice.de/pages/event/type/index.vue?macro=true";
import { default as indexGMX6Mk3Qk8Meta } from "/etc/nginx/sites-available/ulaznice.de/pages/index.vue?macro=true";
import { default as loginWBe4iMHETiMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/login.vue?macro=true";
import { default as indexiVxKthYeB0Meta } from "/etc/nginx/sites-available/ulaznice.de/pages/page/[slug]/index.vue?macro=true";
import { default as indexe6P5NZLkPRMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/payspot/[id]/index.vue?macro=true";
import { default as indexOsqWSK2TeHMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/payspot/event/[id]/index.vue?macro=true";
import { default as indexiLq43UHiyLMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/scanner/[id]/index.vue?macro=true";
import { default as supportFQQD6Hn5fMMeta } from "/etc/nginx/sites-available/ulaznice.de/pages/scanner/support.vue?macro=true";
export default [
  {
    name: "admin-client-id",
    path: "/admin/client/:id()",
    meta: indexA7CZ3Xpga2Meta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/client/[id]/index.vue")
  },
  {
    name: "admin-client-id-payouts",
    path: "/admin/client/:id()/payouts",
    meta: payoutsn6dILLKAJ9Meta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/client/[id]/payouts.vue")
  },
  {
    name: "admin-client-id-stats",
    path: "/admin/client/:id()/stats",
    meta: statsnwmKzreI4wMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/client/[id]/stats.vue")
  },
  {
    name: "admin-client-add",
    path: "/admin/client/add",
    meta: addXgbV0oaE7WMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/client/add.vue")
  },
  {
    name: "admin-client",
    path: "/admin/client",
    meta: index2BuX5Mf1KYMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/client/index.vue")
  },
  {
    name: "admin-deploy",
    path: "/admin/deploy",
    meta: indexLXvG1VhT7KMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/deploy/index.vue")
  },
  {
    name: "admin-event-id-event-manager",
    path: "/admin/event/:id()/event-manager",
    meta: indexss8hUfI7iHMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/event/[id]/event-manager/index.vue")
  },
  {
    name: "admin-event-id",
    path: "/admin/event/:id()",
    meta: indexFY8v0928ItMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/event/[id]/index.vue")
  },
  {
    name: "admin-event-add",
    path: "/admin/event/add",
    meta: add7GstNqrXo3Meta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/event/add.vue")
  },
  {
    name: "admin-event",
    path: "/admin/event",
    meta: indexxEUEGQv3WaMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/event/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexhnmDXrslDmMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/index.vue")
  },
  {
    name: "admin-order-id",
    path: "/admin/order/:id()",
    meta: indexAWrWSHgJBFMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/order/[id]/index.vue")
  },
  {
    name: "admin-order",
    path: "/admin/order",
    meta: index6lPnhw5Gm2Meta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/order/index.vue")
  },
  {
    name: "admin-pages-id",
    path: "/admin/pages/:id()",
    meta: indexyv1oickYpeMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/pages/[id]/index.vue")
  },
  {
    name: "admin-pages-add",
    path: "/admin/pages/add",
    meta: addwhoJTrsswKMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/pages/add.vue")
  },
  {
    name: "admin-pages",
    path: "/admin/pages",
    meta: indexSaJKz81WhJMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/pages/index.vue")
  },
  {
    name: "admin-payouts",
    path: "/admin/payouts",
    meta: indexkT9euVLpJbMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/payouts/index.vue")
  },
  {
    name: "admin-place-id",
    path: "/admin/place/:id()",
    meta: index4TdVT9c7wVMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/place/[id]/index.vue")
  },
  {
    name: "admin-place-add",
    path: "/admin/place/add",
    meta: addm0qeuL0r1vMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/place/add.vue")
  },
  {
    name: "admin-place",
    path: "/admin/place",
    meta: index03MLA3UToAMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/place/index.vue")
  },
  {
    name: "admin-requests",
    path: "/admin/requests",
    meta: indexXiFVxfFIEaMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/requests/index.vue")
  },
  {
    name: "admin-settings-design",
    path: "/admin/settings/design",
    meta: design0WzTzT3NkQMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/settings/design.vue")
  },
  {
    name: "admin-settings-email",
    path: "/admin/settings/email",
    meta: emailJGqFcjTDT5Meta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/settings/email.vue")
  },
  {
    name: "admin-settings",
    path: "/admin/settings",
    meta: indexNuwoOcA5RxMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/settings/index.vue")
  },
  {
    name: "admin-settings-marketing-email",
    path: "/admin/settings/marketing/email",
    meta: emailq2anKU35UVMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/settings/marketing/email.vue")
  },
  {
    name: "admin-settings-marketing",
    path: "/admin/settings/marketing",
    meta: indexCEOciNeq9aMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/settings/marketing/index.vue")
  },
  {
    name: "admin-settings-marketing-sms",
    path: "/admin/settings/marketing/sms",
    meta: smsQZuBfGJVvuMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/settings/marketing/sms.vue")
  },
  {
    name: "admin-settings-marketing-whatsapp",
    path: "/admin/settings/marketing/whatsapp",
    meta: whatsapp1uHAG3Kw8FMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/settings/marketing/whatsapp.vue")
  },
  {
    name: "admin-sponsor-id",
    path: "/admin/sponsor/:id()",
    meta: indexpk0TCUAxwGMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/sponsor/[id]/index.vue")
  },
  {
    name: "admin-sponsor-add",
    path: "/admin/sponsor/add",
    meta: addk2D5Y7pXalMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/sponsor/add.vue")
  },
  {
    name: "admin-sponsor",
    path: "/admin/sponsor",
    meta: index0rYY0SbiuRMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/sponsor/index.vue")
  },
  {
    name: "admin-user-id",
    path: "/admin/user/:id()",
    meta: indexBO1LMQ7jOLMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/user/[id]/index.vue")
  },
  {
    name: "admin-user-add",
    path: "/admin/user/add",
    meta: add7BlkmSQpGpMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/user/add.vue")
  },
  {
    name: "admin-user",
    path: "/admin/user",
    meta: indexYH9BNAL52pMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/admin/user/index.vue")
  },
  {
    name: "archive",
    path: "/archive",
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/archive.vue")
  },
  {
    name: "checkout-cancel",
    path: "/checkout/cancel",
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/checkout/cancel.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indextecaepTb2XMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/checkout/index.vue")
  },
  {
    name: "checkout-success",
    path: "/checkout/success",
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/checkout/success.vue")
  },
  {
    name: "client-id-event",
    path: "/client/:id()/event",
    meta: indexBes5HwpDcwMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/client/[id]/event/index.vue")
  },
  {
    name: "client-id",
    path: "/client/:id()",
    meta: indexBgBcNhYDIaMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/client/[id]/index.vue")
  },
  {
    name: "client-id-payouts-add",
    path: "/client/:id()/payouts/add",
    meta: addWjFa6wly5gMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/client/[id]/payouts/add.vue")
  },
  {
    name: "client-id-payouts",
    path: "/client/:id()/payouts",
    meta: index4enIUcivCpMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/client/[id]/payouts/index.vue")
  },
  {
    name: "client-id-settings",
    path: "/client/:id()/settings",
    meta: settingsO4VYeDH4zlMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/client/[id]/settings.vue")
  },
  {
    name: "collect",
    path: "/collect",
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/collect.vue")
  },
  {
    name: "event-slug",
    path: "/event/:slug()",
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/event/[slug]/index.vue")
  },
  {
    name: "event-genre",
    path: "/event/genre",
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/event/genre/index.vue")
  },
  {
    name: "event-tag",
    path: "/event/tag",
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/event/tag/index.vue")
  },
  {
    name: "event-type",
    path: "/event/type",
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/event/type/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginWBe4iMHETiMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/login.vue")
  },
  {
    name: "page-slug",
    path: "/page/:slug()",
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/page/[slug]/index.vue")
  },
  {
    name: "payspot-id",
    path: "/payspot/:id()",
    meta: indexe6P5NZLkPRMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/payspot/[id]/index.vue")
  },
  {
    name: "payspot-event-id",
    path: "/payspot/event/:id()",
    meta: indexOsqWSK2TeHMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/payspot/event/[id]/index.vue")
  },
  {
    name: "scanner-id",
    path: "/scanner/:id()",
    meta: indexiLq43UHiyLMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/scanner/[id]/index.vue")
  },
  {
    name: "scanner-support",
    path: "/scanner/support",
    meta: supportFQQD6Hn5fMMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.de/pages/scanner/support.vue")
  }
]